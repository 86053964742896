<!-- Vue SFC -->
<template>
  <div id="app">
    <label v-if="labelText" class="tree-label">{{ labelText }}</label>
    <treeselect
      :multiple="true"
      :closeOnSelect="false"
      :alwaysOpen="false"
      :clearable="false"
      :sort-value-by="sortValueBy"
      :options="rootOptions"
      placeholder="Select"
      v-model="selectedValue"
      @input="getSelectedField"
      :id="labelText"
    />
    <treeselect-value :value="value" />
  </div>
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  props: ["rootOptions", "selectedValue", "labelText"],
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getSelectedField() {
      // this.selectedValue = node;
      this.$emit("selectedField", this.selectedValue);
    },
  },
};
</script>
<style>
.vue-treeselect__control {
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid #ced4da;
  height: 38px;
}
.vue-treeselect__multi-value {
  height: 1.8rem;
  /* overflow: scroll; */
  overflow-x: hidden;
}
.tree-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
}
</style>
